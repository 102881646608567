
import { defineComponent, inject } from 'vue';
import { unreadShopMessages } from '@/services/firebase';
import { FbMessageI } from '@/services/firebase/FirebaseService';
import { hasHqMessages } from '@/services/messages';
import { Store } from '@/stores';
import ActionButton from '@/components/Buttons/ActionButton.vue';
import parseISO from 'date-fns/parseISO'

export default defineComponent({
    name: 'MessagesBanner',

    components: {
        ActionButton,
    },

    setup() {
        const store = inject('store') as Store;

        return {
            store,
            hasHqMessages,
            unreadShopMessages
        }
    },

    methods: {
        viewOldest() {
            this.unreadShopMessages.sort((a: FbMessageI, b: FbMessageI) => {
                return parseISO(a.time).getTime() - parseISO(b.time).getTime();
            });

            const oldestOrder = this.unreadShopMessages[0];
            this.$router.push(`/orders/${oldestOrder.orderNumber}`);
        }
    },

    computed: {
        orderLabel(): string {
            return this.unreadShopMessages.length > 1 ? 'Orders Have' : 'Order Has'
        }
    }
})
