
import { IonButton, IonToolbar } from '@ionic/vue';

export default {
    name: 'HeaderTabs',

    components: {
        IonButton,
        IonToolbar,
    },

    props: {
        active: String,
    },
};
