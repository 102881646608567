import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_header_tabs = _resolveComponent("header-tabs")
  const _component_messages_banner = _resolveComponent("messages-banner")
  const _component_orders_table = _resolveComponent("orders-table")
  const _component_ion_content = _resolveComponent("ion-content")
  const _component_ion_page = _resolveComponent("ion-page")

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_header_tabs, { active: "queued" }),
      _createVNode(_component_ion_content, { "scroll-y": true }, {
        default: _withCtx(() => [
          _createVNode(_component_messages_banner),
          (_ctx.shopOrders.pendingNonDeliveryOrders.length)
            ? (_openBlock(), _createBlock(_component_orders_table, {
                key: 0,
                title: "Pending Orders",
                orders: _ctx.shopOrders.pendingNonDeliveryOrders
              }, null, 8, ["orders"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}