
import { defineComponent } from 'vue';
import { IonContent, IonPage } from '@ionic/vue';
import { shopOrders } from '@/services/firebase';
import HeaderTabs from '@/components/Header/HeaderTabs.vue';
import OrdersTable from '@/components/OrdersTable/OrdersTable.vue';
import MessagesBanner from '@/components/Messages/MessagesBanner.vue';

export default defineComponent({
    name: 'OrdersQueued',

    components: {
        IonContent,
        IonPage,
        HeaderTabs,
        OrdersTable,
        MessagesBanner,
    },

    setup() {
        return { shopOrders };
    },
});
